import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

declare let CodeMirror;

@Component({
  selector: 'app-codemirror',
  templateUrl: './codemirror.component.html',
  styleUrls: ['./codemirror.component.css']
})
export class CodemirrorComponent implements OnInit {
  codeMirror: any;
  @ViewChild('textArea') textArea: ElementRef;

  @Input() code: string;
  @Input() options: string;
  @Output() codeChange = new EventEmitter();

  ngOnInit(): void {
    this.codeMirror = CodeMirror.fromTextArea(this.textArea.nativeElement, {
      matchBrackets: true,
      autoCloseBrackets: true,
      mode: {name: 'javascript', jsonld: true},
      lineWrapping: true,
      lineNumbers: true,
      autoRefresh: true
    });
    this.codeMirror.on('change', (obj, change) => {
      this.codeChange.emit(obj.doc.getValue());
    });
    this.codeMirror.setValue(this.code);
  }
}
